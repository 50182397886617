





// init controller
var controller = new ScrollMagic.Controller();
$('.animfadeIn').each(function() {
	var $this = $(this);
	var animdur = $(this).data("animdur") || 1;
	var hook = $(this).data("hook") || 0.8;
	var myScene = new ScrollMagic.Scene({
		triggerElement: this,
		triggerHook: hook,
		reverse: false
	}).setTween(TweenMax.fromTo(this, animdur, {
		autoAlpha: 0,
		y: 20
	}, {
		autoAlpha: 1,
		y: 0,
		force3D: false,
		onComplete: function() {
			$this.css("transform", "none");
		}
	})).addTo(controller);
});

// Select all links with hashes
$('a[href*="#"]')
	// Remove links that don't actually link to anything
	.not('[href="#"]')
	.not('[href="#0"]')
	.click(function(event) {
		// On-page links
		if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
			&& location.hostname == this.hostname) {
			// Figure out element to scroll to
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
				// Does a scroll target exist?
				if (target.length) {
					// Only prevent default if animation is actually gonna happen
					event.preventDefault();
					$('html, body').animate({
						scrollTop: target.offset().top
					}, 1000, function() {
						// Callback after animation
						// Must change focus!
						var $target = $(target);
						$target.focus();
						if ($target.is(":focus")) { // Checking if the target was focused
							return false;
						} else {
							$target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
							$target.focus(); // Set focus again
						};
					});
				}
			}
		});


	$('.clickable-nav').on('click',  function(e) {
		e.stopPropagation();

		$this = $(this);
		$('.clickable-nav').not($this).children('div').slideUp();
		$('.clickable-nav').not($this).children('a').removeClass('active');
		$this.children('div').slideToggle();
		$this.children('div').prev('a').toggleClass('active');
	});

	$(document).on('click keyup', function(e) {
		if (e.which===27 || e.type==='click') {
			$('.clickable-nav').children('div').slideUp();
			$('.clickable-nav').children('a').removeClass('active');
		}
	});

	$(".disableProp").on("click", function (event) {
		//$.fancybox.open({src: "change-password.php", type: "iframe"});
		$.fancybox.open({src: "#change-password", type: ""});
	});
	$(".editprofiledisableProp").on("click", function (event) {
		$.fancybox.open({src: "#edit-profile", type: ""});
	});


	var imageSlider = new Swiper('.imageSlider', {
		disableOnInteraction: false,
		loop: true,
		speed: 1000,
		slidesPerView: 1,
		autoHeight: true,
		simulateTouch: true,
		autoplay: {
			delay: 3000
		},
		navigation: {
			nextEl: '.imageSlider-wrap .swiper-button-next',
			prevEl: '.imageSlider-wrap .swiper-button-prev',
		}
	});

	$(".imageSlider").hover(function() {
		imageSlider.autoplay.stop();
	}, function() {
		imageSlider.autoplay.start();
	});


	players = Array.from(document.querySelectorAll('.myplyr')).map(function (player) {
		return new Plyr(player);
	});
	//--- VIDEO PLAYER --//